import { CATEGORY_PAGE } from '../../../constants';
import { getSpecialBuyAnalytics } from '../search/util.search.analytics';

export const getUrlParameter = (name) => {
  const param = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp('[\\?&]' + param + '=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getUrlHashInfo = (hash) => {
  const cleaned = decodeURIComponent(hash);
  const params = cleaned?.split('&');
  const searchTermRaw = params?.find((param) => param?.includes('searchRedirect'));
  const semanticTokenRaw = params?.find((param) => param?.includes('semanticToken'));

  return {
    searchTerm: searchTermRaw?.split('=')?.[1] || '',
    semanticToken: semanticTokenRaw?.split('=')?.[1] || ''
  };
};

export const getOnsiteSearchInfo = ({ browseModel, experienceName = null } = {}) => {
  let searchTerm = getUrlParameter('searchRedirect');
  let correctedTerm = '';
  let semanticSearchTokens = getUrlParameter('semanticToken')
    || browseModel?.metadata?.analytics?.semanticTokens || '';
  let resultsCategory = '';
  let resultsType = '';
  let searchIntent = '';

  if (window.location.search.includes('visNavSearch')) {
    searchTerm = getUrlParameter('visNavSearch');
    searchIntent = 'visual nav';
  }

  if (!searchTerm && window.location.hash) {
    const { searchTerm: decodedSearchTerm, semanticToken } = getUrlHashInfo(window.location.hash || '');
    if (decodedSearchTerm && semanticToken) {
      searchTerm = decodedSearchTerm;
      semanticSearchTokens = semanticToken || semanticSearchTokens;
      resultsCategory = 'search all';
      resultsType = 'search results';
      searchIntent = 'default';
    }
  }
  if (searchTerm && semanticSearchTokens?.includes('sc:{')) {
    resultsType = 'spell correction';
    searchTerm = /st:{(.*?)}/.exec(semanticSearchTokens)?.[1] || searchTerm;
    correctedTerm = /sc:{(.*?)}/.exec(semanticSearchTokens)?.[1] || correctedTerm;
    resultsCategory = 'search all';
    searchIntent = 'default';
  }
  let visualCategoryIds = (browseModel?.relatedResults?.visualNavs || [])
    .map((visualNav) => ({ slot: visualNav?.categoryId }));

  // When no promotions in the visnav and special buy exist in the refinements, push saving slot for HD Home Only.
  if (experienceName === 'hd-home' || experienceName === 'general-merchandise') {
    const specialBuyAnalytics = getSpecialBuyAnalytics({ model: browseModel });
    if (specialBuyAnalytics) visualCategoryIds.push(specialBuyAnalytics);
  }

  // Added due to current double encoding from header - TODO: Reevaluate need for this once header is migrated
  if (searchTerm.indexOf('%20') > -1) searchTerm = decodeURIComponent(searchTerm);

  if (experienceName && browseModel?.searchReport?.keyword && !searchTerm) {
    searchTerm = browseModel.searchReport.keyword;
    resultsCategory = 'search all';
    resultsType = 'search results';
    searchIntent = 'default';
  }

  const referrerHostname = document.referrer ? new URL(document.referrer).hostname : '';
  const isTHDReferrer = referrerHostname.includes('homedepot.com');
  const searchHistory = localStorage.header_search_history;

  if (!searchTerm && isTHDReferrer && searchHistory) {
    searchTerm = decodeURI(JSON.parse((decodeURIComponent(JSON.parse(searchHistory).value)))[0].keyword);
  }

  let onsiteSearchInfo = {
    experienceName,
    correctedTerm,
    numberOfResults: (browseModel?.searchReport?.totalProducts ?? 0).toString() || '0',
    resultsCategory,
    resultsType,
    searchIntent,
    searchTerm,
    searchTypeAhead: '',
    semanticSearchTokens,
    visualCategoryId: visualCategoryIds || []
  };

  return onsiteSearchInfo;
};

export const isContentOnlyPage = ({ browseModel }) => {
  const stringifiedTemplates = browseModel?.templates || [];
  const stringifiedPartialTemplates = browseModel?.partialTemplates || [];

  const templates = stringifiedTemplates?.map((template) => JSON.parse(template)) || [];
  const partialTemplates = stringifiedPartialTemplates?.map((template) => JSON.parse(template)) || [];

  const { options = {}, nexGen = false } = templates[0] || {};
  const { content_type: contentType = '' } = options;
  const { PLP_Banner: plpBanner = {} } = partialTemplates[0] || {};
  const { Content = [] } = plpBanner;
  const hasContent = templates && (contentType === CATEGORY_PAGE || Content.length > 1);
  const hasProductPods = browseModel?.products?.length;
  return nexGen || (hasContent && !hasProductPods);
};